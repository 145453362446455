@use 'sass:math';
$root-font-min-size: 10px;
$root-font-base-size: 47px;
$root-font-max-size: 47px;
$design-width: 1800px;
$design-height: 1200px;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern=1';
  -webkit-font-kerning: normal;
  -moz-font-kerning: normal;
  font-feature-settings: 'kern';
  font-kerning: normal;

  font-size: 10px;

  @include md {
    @include vh-font-size(
      $root-font-base-size,
      $min-px-size: $root-font-min-size,
      $max-px-size: $root-font-max-size,
      $design-height: $design-height
    );
    @media (max-width: 1200px) {
      // color: red;
      @include vw-font-size(
        $root-font-base-size,
        $min-px-size: $root-font-min-size,
        $max-px-size: $root-font-max-size,
        $design-width: $design-width
      );
      @media (max-height: 700px) {
        // color: blue;
        @include vh-font-size(
          $root-font-base-size,
          $min-px-size: $root-font-min-size,
          $max-px-size: $root-font-max-size,
          $design-height: $design-height
        );
      }
    }
    // color: blue;
    // @media (min-width: 1500px) {
    // @include vh-font-size(
    //   $root-font-base-size,
    //   $min-px-size: $root-font-min-size,
    //   $max-px-size: $root-font-max-size,
    //   $design-height: $design-height
    // );
  }
}

body {
  @include body();
}

h1 {
  // @include heading-x-large;
}
h2 {
  @include heading-medium;
}
h3 {
  // @include heading-small-light;
}
h4 {
  // @include heading-small-light;
}

// UPDATE_ME
p {
  padding-bottom: var(--space);
}
