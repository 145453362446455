:root {
  --vh: 100vh;
  --standard-cols: 16;
  --standard-gap: 0px;
  --page-gutter: 14px;
  --mobile-header-height: 50px;
  --pauseDelayMax: 0s;
  --pauseDelay: 0s;

  // default settings for padding/margins
  --space-small: #{rem(10)};
  --space: #{rem(12)};
  --space-medium: #{rem(24)};
  --space-large: #{rem(40)};
  --space-x-large: #{rem(48)};
  --space-xx-large: #{rem(52)};

  // DEV NOTE: dynamicly set based on transition type
  // has to be MS
  --pageTransitionTime: 8000ms;
  // SET BY JS ON SCROLL
  --menuSlideTime: 0s;
  --menuSlideDelay: 0.5s;

  --slideWidth: 100px;
  --slideHeight: 100px;
  --slideMargin: 31px;
  @include tabletOnly {
    --page-gutter: 21px;
    --mobile-header-height: 76px;
  }

  @include md {
    // DEV NOTE: dynamicly set based on transition type
    --pageTransitionTime: 1000ms;
    --page-gutter: #{rem(25)};

    --standard-gap: #{rem(0)};
    --space-x-large: #{rem(64)};
    --space-xx-large: #{rem(100)};

    // this has to be a VW measurement as it's also used in JS for better performance
    --slideWidth: 35vw;
    --slideHeight: 55vh;
    --slideMargin: #{rem(31)};
    // 1400 139 are the logo dimensions
  }

  @include customTablet {
    // this will change the slides to be square sized
    --slideHeight: calc(var(--slideWidth) - var(--slideMargin));
  }
}
