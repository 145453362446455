@import '../scss/components_base';
.mainMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9;
  @include md {
  }
}

.preloadImages {
  position: absolute;
  top: 0;
  pointer-events: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
.chapterNav {
  position: fixed;
  bottom: 20px;
  right: var(--page-gutter);
  z-index: 10;
  pointer-events: all;

  @include md {
    bottom: rem(35);
    right: rem(35);
  }
}
.chapterNavMobile {
  position: fixed;
  top: var(--page-gutter);
  right: var(--page-gutter);
  z-index: 10;
  mix-blend-mode: difference;
  @include md {
    display: none;
  }
}
.chapterNavMobileIsOpen {
  mix-blend-mode: normal;
}
.chapterNavHide {
  opacity: 0;
}
