@import './utilities/layout';
@import './utilities/show-hide';

// UPDATE_ME:
// You _may_ opt to add more utility classes here.
// e.g. spacing, display properties, font utilities (size, weight etc).
// Please note these can get pretty big, especially responsive grid utilities,
// so only add what is necessary.

.u-bounce-remover {
  // ADDING position:fixed on mobile for this project messes with the safari ios dock menu

  @include md {
    overflow: hidden;
    position: fixed;
    height: var(--vh);
    width: 100%;
  }
}

.u-no-overflow-x {
  // having overflow will act as a scollstopper and can't call "window.scrollTo(0,0)"
  // we need to toggle this
  overflow-x: hidden;
}
.u-remove-animation {
  transition: none !important;
}
