// Layout utilities
.u-content-width {
  @include content-width;
}
.u-grid-standard {
  @include grid-standard;
}

.u-no-body-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
