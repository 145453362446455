.fade-appear {
}
.fade-appear-active {
}
.fade-appear-done {
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s, transform 0.5s;
}
.fade-enter-done {
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}
.fade-exit-done {
}
