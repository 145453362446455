@import '../../scss/components_base';

.menuContainer {
  display: flex;
  align-items: center;
}
.prev {
  @include btn;
  font-size: 1px;
  line-height: 0;
  align-self: flex-end;
  transform: scale(0);
  transition: transform 0.15s;

  svg {
    transition: all 0.15s;
    height: 20px;
  }
  @include tabletOnly {
    svg {
      height: 34px;
    }
  }
  @include md {
    svg {
      height: rem(34);
    }
    &:hover {
      svg {
        transform: scale(0.8);
      }
    }
  }
}
.prevIsVisible {
  transition-delay: 1s;
  transform: scale(1);
}
.next {
  @include btn;
  font-size: 1px;
  line-height: 0;
  align-self: flex-end;
  transform: scale(0);
  transition: transform 0.15s;
  transition-delay: 0s;
  transform-origin: center;
  margin-left: 30px;

  svg {
    height: 20px;
    transition: all 0.15s;
  }
  @include tabletOnly {
    svg {
      height: 34px;
    }
  }

  @include md {
    margin-left: 0;
    svg {
      height: rem(34);
    }
    &:hover {
      svg {
        transform: scale(0.8);
      }
    }
  }
}
.nextIsVisible {
  transition-delay: 1s;
  transform: scale(-1) translateY(0);
}
.toggle {
  @include btn;
  font-size: 1px;
  align-self: flex-end;
  line-height: 0;
  svg {
    height: 20px;
    rect {
      stroke: white;
    }
  }
  @include tabletOnly {
    svg {
      height: 30px;
    }
  }
  @include md {
    position: relative;
    top: auto;
    right: auto;
    svg {
      height: rem(33);
      rect {
        stroke: black;
      }
    }
    margin-left: rem(20);
    margin-right: rem(20);

    &:hover {
      svg {
        height: rem(33);
        rect {
          stroke: black;
          fill: black;
        }
      }
    }
  }
}
.showToggleOnly {
}
.showToggleAndArrows {
  // on mobile this is never a valid combo, we hide the tiggle/toggle
  display: none;
  @include md {
    display: block;
  }
}

.toggleSelected {
  svg {
    fill: white;
    rect {
      stroke: white;
    }
  }
}
