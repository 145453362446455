@import '../../scss/components_base';

.menuContainer {
  @include grid-standard;

  pointer-events: none;
  width: 100%;
  height: 100%;
}

.logo {
  flex: 1;
  position: relative;
}
.logoBottom {
  flex: 1;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
}

.logoRotator {
  // @include gutter;
  padding: 0;
  margin: 0;
  position: absolute;

  bottom: calc(100% - var(--page-gutter) - var(--page-gutter));

  left: calc(var(--page-gutter) + var(--page-gutter));
  transform-origin: bottom left;
  transform: rotate(90deg);
}
.logoRotatorBottom {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: calc(var(--page-gutter) + var(--page-gutter));
  right: calc(100% - var(--page-gutter) - var(--page-gutter));
  transform-origin: bottom right;
  transform: rotate(90deg);
  text-align: right;
}
.url {
  position: absolute;
  // width: 100vh;
  line-height: 1;

  left: 100px; //calc(100% - var(--page-gutter));
  top: 100px;
  text-align: left;

  text-align: center;
  top: calc(50% - rem(30));
  left: var(--page-gutter);
  transform-origin: bottom center;
  transform: rotate(90deg);
}
.leftContainer {
  display: none;
  @include md {
    display: block;
    font-size: rem(34);
    line-height: 1.22;
    letter-spacing: 2.31959px;
    color: white;

    grid-column-start: 1;
    grid-column-end: 4;
    transform: translateX(-100%);
    // background: blue;
    transition: transform var(--pageTransitionTime);

    position: relative;
    transition-delay: 0.25s;
  }
}
.listContainer {
  @include gutter;

  display: flex;
  flex-direction: column;

  grid-column-start: 1;
  grid-column-end: -1;
  background: #333c21;
  transform: translateX(100%);
  transition: transform var(--menuSlideTime);
  transition-delay: var(--menuSlideDelay);
  height: var(--vh);
  overflow: auto;
  @include md {
    @include gutter-double;
    grid-column-start: 9;
  }
}
.logoMobile {
  color: white;
  margin-top: 100px;
  @include md {
    display: none;
  }
}
.leftContainerSlideIn {
  pointer-events: all;
  transform: translateX(0);
  transition: transform 500ms;
  transition-delay: 0s;
}
.listContainerSlideIn {
  pointer-events: all;
  transform: translateX(0);
  transition: transform 500ms;
  transition-delay: 0.25s;
  // safari fix to prevent render artifacts, yeah for safari....
  transform: translate3d(0, 0, 0);
}
.listContainerSlideInIntro {
  pointer-events: all;
  // transition: 0ms;
}
.homeLink {
  @include unstyled-link;
  &:hover {
    color: var(--color-dark);
  }
}
.logoMobileLink {
  @include unstyled-link;
}
.homeLinkDisabled {
}
