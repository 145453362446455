// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-dark: #222222;
  --color-rollover: #ffffff;
}
//
