@import '../../scss/components_base';

.menuContainer {
  width: 100%;
  flex: 1;
  @include md {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.list {
  @include list-unstyled;
  line-height: 1.7;
  @include md {
    @include body();
  }
}

.listItemLink {
  @include unstyled-link();

  display: block;
  width: 100%;
  // text-align: left;

  color: white;
  user-select: none;
  &:focus {
    outline: none;
  }
  @media (hover: hover) {
    &:hover {
      color: var(--color-dark);
    }
  }

  @include md {
    line-height: 1.6;
  }
}
.listItemLinkActive {
  user-select: none;
  @include md {
    color: var(--color-dark);
  }
}
