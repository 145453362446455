@import '../../scss/components_base';

.container {
  text-transform: uppercase;
  p {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    letter-spacing: 0.5px;
    @include md {
      // @include body();
      line-height: 1.22;
      letter-spacing: 2.31959px;
      line-height: 1;
    }
  }
}
