// @font-face {
//   font-family: 'HelveticaNeueLTPro';
//   font-style: normal;
//   font-weight: normal;
//   src: url('./fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
//     url('./fonts/HelveticaNeueLTPro-Roman.woff') format('woff');
// }


@font-face {
  font-family: 'UntitledSansWeb';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Untitled/UntitledSansWeb-Regular.woff2') format('woff2'),
    url('./fonts/Untitled/UntitledSansWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'UntitledSansWeb';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/Untitled/UntitledSansWeb-RegularItalic.woff2') format('woff2'),
    url('./fonts/Untitled/UntitledSansWeb-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'UntitledSansWeb';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Untitled/UntitledSansWeb-Medium.woff2') format('woff2'),
    url('./fonts/Untitled/UntitledSansWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'UntitledSansWeb';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/Untitled/UntitledSansWeb-MediumItalic.woff2') format('woff2'),
    url('./fonts/Untitled/UntitledSansWeb-MediumItalic.woff') format('woff');
}