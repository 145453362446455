/**
 * Below you will find `@supports not (-webkit-touch-callout: none)` and `@supports (-webkit-touch-callout: none)` usages.
 * The first rule targets browsers other than iOS browsers, and the second is the opposite.
 * We are using it because using `transform` in combination with fixed elements causes flickering in iOS browsers.
 * To circumvent that, we use non `transform` styles, such as `left` ONLY for iOS browsers.
 */

/* ==========================================================================
   Glide left
   ========================================================================== */

@supports not (-webkit-touch-callout: none) {
  .splitSlide {
    transition: transform var(--pageTransitionTime);
    backface-visibility: hidden; /* Necessary because of performance on Firefox */
    transition-timing-function: ease-in-out;

    &.enter {
      transform: translateX(0);
    }

    &.enterActive,
    &.enterDone,
    &.exit {
      // remove transform ,otherwise "fixed" won't work
      transform: none;
    }

    &.exit {
      :global {
        .js-global-pageTransitionHook__slide-content {
          // visibility: false;
          opacity: 0;
        }
      }
    }
    &.exitActive,
    &.exitDone {
      transform: translateX(-100vw);
      :global {
        .js-desktop-title {
          display: none;
        }
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  // .splitSlide {
  //   position: relative;
  //   transition: left var(--pageTransitionTime);

  //   &.enter {
  //     left: 100vw;
  //   }

  //   &.enterActive,
  //   &.enterDone,
  //   &.exit {
  //     left: 0;
  //   }
  //   &.exit {
  //     :global {
  //       .js-global-pageTransitionHook__slide-content {
  //         // visibility: false;
  //         opacity: 0;
  //       }
  //     }
  //   }

  //   &.exitActive,
  //   &.exitDone {
  //     left: -0vw;
  //   }
  // }
}

/* ==========================================================================
   Quick
   ========================================================================== */

@supports not (-webkit-touch-callout: none) {
  .quick {
    &.exit {
      :global {
        .js-global-pageTransitionHook__slide-content {
          // visibility: false;
          opacity: 0;
        }
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .quick {
    &.exit {
      :global {
        .js-global-pageTransitionHook__slide-content {
          // visibility: false;
          opacity: 0;
        }
      }
    }
  }
}
